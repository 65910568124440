.ui--promotion {
  &-container {
    @apply mt-4 grid grid-cols-3 gap-4 rounded-2xl bg-theme-dark p-4 max-sm:grid-cols-2;
  }

  &-item {
    @apply overflow-hidden rounded-lg bg-theme-light/10 ring-1 ring-white/20;

    .title {
      @apply text-lg font-bold text-theme-light;
    }
  }
}
