// SET global classes.
.ui- {
  &-app {
    &-container {
    }
  }

  &-router {
    &-view {
      @apply px-4 pb-[100px] max-sm:px-2;
    }
  }

  &-page {
    &-message {
      @apply mb-4 mt-2 flex h-10 select-none items-center overflow-x-hidden rounded-xl ring-1 ring-white/10;
    }
  }
}
