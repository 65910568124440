@layer components {
  .ui--markdown {
    &-blog {
      h1 {
        @apply text-left text-2xl text-theme;
      }

      h2,
      h3 {
        @apply mt-6 text-center text-2xl font-semibold text-theme;
      }

      p {
        @apply my-2;
      }

      a {
        @apply text-theme;
      }

      blockquote {
        @apply my-4 border-l-4;

        a {
          @apply w-full text-center text-theme;
        }

        img {
          @apply my-0;
        }
      }

      pre {
        @apply text-center text-base font-semibold;
      }

      ul,
      ol {
        @apply mb-4 mt-4 list-disc pl-10;
      }

      li {
        @apply mb-1;
      }

      img {
        @apply mx-auto my-6 rounded;
      }
    }

    &-promotion {
      ul,
      ol {
        @apply mb-3 mt-1 list-disc pl-5 text-sm;
      }
    }
  }
}
